<template>
  <MitchOS :default-tasks="defaultTasks">

    <!-- DEBUG ICONS -->
    <DebugMenuIcons />

    <!-- THE MENUBAR  -->
    <transition
      enter-active-class="menubarEnter animated slideInDown faster delay-3s"
      leave-active-class="menubarLeave animated slideOutUp faster"
    >
      <TheMenubar v-if="showMenubar" />
    </transition>

    <!-- THE STAGE -->
    <TheStage />

    <!-- THE DASHBOARD -->
    <transition
      enter-active-class="dashboardEnter animated slideInUp"
      leave-active-class="dashboardLeave"
    >
      <TheDashboard v-if="showDashboard" />
    </transition>

  </MitchOS>
</template>

<script>
  import { mapGetters } from "vuex";
  import DebugMenuIcons from "@/components/MitchOS/Programs/Debug-v1/DebugMenuIcons.vue";
  import MitchOS from "@/components/MitchOS/MitchOS.vue";
  import TheDashboard from "@/components/MitchOS/TheDashboard/TheDashboard.vue";
  import TheMenubar from "@/components/MitchOS/TheMenubar/TheMenubar.vue";
  import TheStage from "@/components/MitchOS/TheStage/TheStage.vue";

  const manifest = window.composeManifest([
    // './fonts/', -- makes dev slow
    "./img/",
    "./MitchOS/Backdrops/Lobby/assets/",
    "./MitchOS/Backdrops/WallpaperIcons/assets/",
    "./MitchOS/TheDashboard/", // drill all folders
  ]);

  export default {
    name: "BroadcastAllView",
    components: {
      DebugMenuIcons,
      MitchOS,
      TheDashboard,
      TheMenubar,
      TheStage,
    },
    props: {
      preloadComplete: {
        type: Boolean,
        required: false,
      },
    },
    data() {
      return {
        defaultTasks: [
          { uuid: "76e6f1e7-2d7b-4cc9-adfc-b73690c5e985" }, // Alerts
          { uuid: "559c502f-6dc2-47b4-b086-e27d7d44c68d" }, // MediaPoster
          { uuid: "cb061b62-0fea-4398-aa1d-5f747331a79d" }, // Soundboard
          { uuid: "86b54c6e-f757-4296-8743-40a06207bb71001" }, // ScreenShare
        ],
      };
    },
    computed: {
      ...mapGetters({
        getActiveTask: "tasks/getActiveTask",
        isLobby: "scenes/isLobby",
        isStartingOrEnding: "scenes/isStartingOrEnding",
      }),
      discordMode() {
        return !!this.getActiveTask("system", "Discord Mode");
      },
      showDashboard() {
        return !this.isLobby && !this.discordMode;
      },
      showMenubar() {
        return !this.discordMode && !this.isStartingOrEnding;
      },
    },
    created() {
      //-----------------------------------------------------------------
      // EMIT - REQUEST PRELOADER
      // Each view has its own set of assets to load
      //-----------------------------------------------------------------

      this.$emit("request-preloader", {
        manifest,
      });
    },
  };
</script>

<style lang="scss" scoped>
  .dashboardEnter {
    // animation-delay: 300ms;
  }

  .dashboardLeave {
    animation: none;
  }
</style>